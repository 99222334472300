<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div
            class="d-flex justify-content-between align-items-center px-1 py-1"
          >
            <div>
              <h3 class="m-0 py-1">
                {{ $t("Departamentos") }}
              </h3>
            </div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-right">
                <b-link :to="{ name: 'createDepartment' }">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-1 text-nowrap"
                  >
                    {{ $t("AñadirDepartamento") }}
                  </b-button>
                </b-link>
              </div>
              <span
                class="ml-1 cursor-pointer"
                @click="visibleFilter = !visibleFilter"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="20"
                />
              </span>
            </div>
          </div>
          <div>
            <b-collapse
              id="collapse"
              v-model="visibleFilter"
              class="mt-2"
            >
              <div class="px-2">
                <b-row>
                  <b-col
                    v-if="role === 'admin_empresa' && !currentClient"
                    sm="4"
                  >
                    <b-form-group
                      :label="$t('Cliente')"
                      label-for="filter-client"
                    >
                      <v-select
                        v-model="client"
                        label="name"
                        :filterable="true"
                        :searchable="true"
                        :options="listClients"
                        :placeholder="$t('Cliente')"
                      >
                        <template
                          slot="option"
                          slot-scope="option"
                        >
                          {{ option.name }}
                        </template>
                        <template
                          slot="selected-option"
                          slot-scope="option"
                        >
                          {{ option.name }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="$t('Busqueda')"
                      label-for="filter-search"
                    >
                      <b-row>
                        <b-col sm="8">
                          <b-form-input
                            v-model="searchTerm"
                            :placeholder="$t('Busqueda')"
                            type="text"
                            class="d-inline-block"
                          />
                        </b-col>
                        <b-col
                          sm="4"
                          style="padding-left: 0px"
                        >
                          <b-button
                            variant="primary"
                            @click="handleSearch"
                          >
                            {{ $t("Buscar") }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <b-table
            hover
            responsive
            :items="items"
            :fields="seleccionarcolumnas"
          >
            <template #cell(client)="data">
              <span v-if="data.item.client">
                {{ data.item.client.name }}
              </span>
            </template>
            <template #cell(actions)="{ item }">
              <span>
                <b-link
                  :to="{ name: 'editDepartment', params: { id: item.id } }"
                  class="mr-1"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    size="16"
                  />
                </b-link>
                <span
                  class="text-danger cursor-pointer"
                  @click="deleteDepartment(item)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
        </b-card>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="ml-50 mr-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap">
              {{ $t("Total") }}: {{ totalItems }}</span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BLink,
  BButton,
  BCard,
  BTable,
  BCol,
  BRow,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BCollapse,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BPagination,
    BFormInput,
    BFormSelect,
    BTable,
    BButton,
    BCol,
    BRow,
    BLink,
    BCollapse,
    vSelect,
    BFormGroup,
  },
  data() {
    return {
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      visibleFilter: false,
      pages: ['10', '15', '25'],
      client: '',
      columnsClient: [
        {
          label: this.$t('Nombre'),
          key: 'name',
        },
        {
          label: this.$t('Acciones'),
          key: 'actions',
          class: 'text-right',
        },
      ],
      columns: [
        {
          label: this.$t('Nombre'),
          key: 'name',
        },
        {
          label: this.$t('Cliente'),
          key: 'client',
        },
        {
          label: this.$t('Acciones'),
          key: 'actions',
          class: 'text-right',
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters({
      currentCompany: 'companies/getCurrentCompany',
      currentClient: 'clients/getCurrentClient',
      items: 'departments/getItems',
      totalItems: 'departments/getTotalItems',
      listClients: 'clients/getItems',
      currentUser: 'auth/getUser',
      role: 'auth/getRole',
    }),
    seleccionarcolumnas() {
      if (this.currentClient) {
        return this.columnsClient
      }
      return this.columns
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    client() {
      this.chargeData()
    },
  },
  created() {
    this.getListClients({
      company: this.currentCompany.id,
      page: 1,
      per_page: 999999,
      search: '',
    })
    this.chargeData()
  },
  methods: {
    ...mapActions({
      list: 'departments/getListDepartments',
      delete: 'departments/delete',
      getListClients: 'clients/getListClients',
    }),
    chargeData() {
      let client = this.currentClient?.id
      if (this.role !== 'admin_cliente' && !client) {
        client = this.client?.id
      }
      this.list({
        client,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    async deleteDepartment(department) {
      const confirmation = await this.$bvModal
        .msgBoxConfirm(this.$t('EliminarConfirm', { nombre: department.name }), {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: this.$t('Eliminar'),
          cancelTitle: this.$t('Cancelar'),
          cancelVariant: 'outline-danger',
          centered: true,
        })

      if (!confirmation) {
        return
      }

      await this.delete(department.id)
      this.chargeData()
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
